import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import NewReport from "./pages/NewReport/NewReport";
import TopBar from "./Components/TopBar/TopBar";
import Toast from "./Shared/Toast";
import LoginPage from "./pages/LoginPage/LoginPage";
import BottomBar from "./Components/BottomBar/BottomBar";
import Reports from "./pages/Reports/Reports";
import axios from "axios";
import baseURL from "./Shared/baseURL";
import Loading from "./Components/Loading/Loading";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // use ref to prevent infinite loop
  const isLoggedInRef = useRef(false);
  isLoggedInRef.current = isLoggedIn;

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${baseURL}/isLoggedin`, {
          withCredentials: true,
        });
        const isLoggedinServer = response.status === 200;
        setIsLoggedIn(isLoggedinServer);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        console.error(error);
      }
    };
    checkLoginStatus();
  }, []);

  const content = isLoading ? (
    <Loading />
  ) : (
    <>
      {isLoggedInRef.current && <TopBar />}
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedInRef.current ? (
                <NewReport />
              ) : (
                <LoginPage onLogin={setIsLoggedIn} />
              )
            }
          />
          {isLoggedInRef.current && (
            <>
              <Route exact path="/yenirapor" element={<NewReport />} />
              <Route exact path="/raporlar" element={<Reports />} />
            </>
          )}

          <Route
            path="/login"
            exact
            element={<LoginPage onLogin={setIsLoggedIn} />}
          />
          <Route
            path="*"
            element={
              <div>
                <h1> Aradığınız sayfa bulunamadı</h1>
                <a href="/">Anasayfaya dön</a>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );

  return (
    <div className="App">
      {content}
      <Toast />
      <BottomBar />
    </div>
  );
}

export default App;

/*
    {isLoggedInRef && (
            <>
              <Route exact path="/yenirapor" element={<NewReport />} />
              <Route exact path="/raporlar" element={<Reports />} />
            </>
          )}
  <ProtectedRoute
            key={"yenirapor"}
            path="/yenirapor"
            component={NewReport}
            isAuthenticated={isLoggedIn}
            exact
          />
          <ProtectedRoute
            key={"raporlar"}
            path="/raporlar"
            component={Reports}
            isAuthenticated={isLoggedIn}
            exact
          />
          */
