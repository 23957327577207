import React from "react";
import StepProgress from "../../Components/StepProgress/StepProgress";
import "./NewReportStyle.css";
export default function NewReport() {
  return (
    <div className="page-container" id="new-report-page">
      <h1>Yeni Rapor Yükleme</h1>
      <StepProgress />
    </div>
  );
}
