import axios from "axios";
import React, { useState, useEffect } from "react";
import ReportItem from "../../Components/ReportItem/ReportItem";
import "./ReportsStyle.css";
import baseURL from "../../Shared/baseURL";
import Loading from "../../Components/Loading/Loading";
export default function Reports() {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      setIsLoading(true);
      axios
        .get(`${baseURL}/reports`, {
          withCredentials: true,
        })
        .then((response) => {
          setReports(response.data);
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
      setError(err);
      setIsLoading(false);
    }
  }, []);

  function searchFile(searchText) {
    try {
      axios
        .get(`${baseURL}/reports?search=${searchText}`, {
          withCredentials: true,
        })
        .then((response) => {
          setReports(response.data);
        });
    } catch (err) {
      console.log(err);
      setError(err);
    }
  }

  const reportsList =
    reports.length === 0 ? (
      <tr>
        <td>Rapor bulunamadı</td>
      </tr>
    ) : (
      reports.map((report) => <ReportItem key={report.id} report={report} />)
    );

  const content = isLoading ? (
    <Loading />
  ) : error ? (
    <div>
      <h1>Reports</h1>
      <p>Error: {error}</p>
    </div>
  ) : (
    <table>
      <tbody>{reportsList}</tbody>
    </table>
  );

  return (
    <div className="page-container" id="reports-page">
      <div>
        <h1>Raporlar</h1>
      </div>
      <div>
        <input
          type="text"
          placeholder="Rapor Ara"
          onInput={(e) => {
            searchFile(e.target.value);
          }}
        />
      </div>
      <div>{content}</div>
    </div>
  );
}
