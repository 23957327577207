// create a login page with a form that has a username and password field and a submit button
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../Constants";
import "./LoginPageStyle.css";
import axios from "axios";
import baseURL from "../../Shared/baseURL";
import { toast } from "react-toastify";
import Loading from "../../Components/Loading/Loading";
export default function LoginPage(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const history = useNavigate();
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };
    axios
      .post(
        `${baseURL}/login`,
        {
          username: username,
          password: password,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          props.onLogin(true);
          toast.success("Giriş Başarılı", {
            toastId: "loginSuccess",
          });
          setLoading(false);
          history("/yenirapor");
        } else {
          props.onLogin(false);
          setLoading(false);

          toast.error("Kullanıcı adı veya şifre hatalı", {
            toastId: "loginError",
          });
        }
      })
      .catch((err) => {
        props.onLogin(false);
        setLoading(false);

        if (err.response) {
          if (err.response.status === 401) {
            toast.error("Kullanıcı adı veya şifre hatalı", {
              toastId: "loginError",
            });
          } else {
            toast.error("Beklenmedik Hata. Tekrar Deneyiniz.", {
              toastId: "loginError",
            });
          }
        } else {
          toast.error("Beklenmedik Hata. Tekrar Deneyiniz.", {
            toastId: "loginError",
          });
        }
      });
  };
  const content = loading ? (
    <Loading />
  ) : (
    <div id="login-page-div">
      <img alt="Yerson Logo" src={CONSTANTS.YERSON_LOGO_SOURCE} />

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Kullanıcı Adı"
        />
        <div id="password-div">
          <input
            type={isShowPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Şifre"
            id="password-input"
          />
          <span
            onClick={() => {
              setIsShowPassword(!isShowPassword);
            }}
          >
            {isShowPassword ? "Gizle" : "Göster"}
          </span>
        </div>

        <button>Giriş Yap</button>
      </form>
    </div>
  );

  return (
    <div className="page-container" id="login-page">
      {content}
    </div>
  );
}
