import axios from "axios";
import React, { useState } from "react";
import FormData from "form-data";
import { toast } from "react-toastify";
import baseURL from "../../Shared/baseURL";

export default function SubmitReport(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedFilePath, setUploadedFilePath] = useState(null);
  async function uploadFile() {
    setIsSubmitting(true);

    const form = new FormData();
    form.append("fileName", props.file.current.name);
    form.append("qrDataURL", props.qrCode.current.qrDataURL);
    form.append("fileID", props.qrCode.current.fileID);
    form.append("qrCode", props.qrCode.current.qrCode);
    form.append("file", props.file.current);

    try {
      setIsLoading(true);
      const response = await axios.post(`${baseURL}/upload`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        setUploadedFilePath(response.data);
        setIsLoading(false);
        props.validator(true);
      } else {
        throw new Error("Dosya Yüklenemedi");
      }
    } catch (err) {
      console.log(err);
      setIsError(true);
      toast.error("Dosya Yüklenemedi", {
        toastId: "uploadFileError",
      });
      setIsLoading(false);
      props.validator(true);
    }
  }

  function retryUpload() {
    setIsError(false);
    setIsSubmitting(false);
  }

  const beforeSubmitContent = (
    <div id="qr-code-div">
      <div>
        <p>Rapor: {props.file.current.name}</p>
        <div id="qr-code-img-div">
          <img id="qrCodeImg" src={props.qrCode.current.qrCode} alt="qrCode" />
        </div>
        <button onClick={uploadFile}>Raporu Yükle</button>
      </div>
    </div>
  );

  const afterSubmitContent = isLoading ? (
    <div>
      <h3>Dosya Yükleniyor...</h3>
    </div>
  ) : (
    <div>
      {isError ? (
        <div>
          <h3>Dosya Yüklenemedi</h3>
          <button onClick={retryUpload}>Tekrar Dene</button>
        </div>
      ) : (
        <div>
          <h3>Dosya Yüklendi</h3>
          <a href={uploadedFilePath} target="_blank" rel="noreferrer">
            {" "}
            Yüklenen Dosyayı Görüntüle
          </a>
        </div>
      )}
    </div>
  );

  const content = isSubmitting ? afterSubmitContent : beforeSubmitContent;

  return content;
}
