import React from "react";
import "./BottomBarStyle.css";
import { CONSTANTS } from "../../Constants";
export default function BottomBar() {
  return (
    <div className="bottom-bar">
      <img alt="Yerson Logo" src={CONSTANTS.YERSON_BOTTOM_LOGO_SOURCE} />
    </div>
  );
}
