import React from "react";
import ReactLoading from "react-loading";
import "./LoadingStyle.css";

export default function Loading() {
  return (
    <div className="loading">
      <ReactLoading type={"spin"} color={"#000000"} height={128} width={45} />
    </div>
  );
}
