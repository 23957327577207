import React from "react";
import { CONSTANTS } from "../../Constants";
import "./TopBarStyle.css";
import axios from "axios";
import baseURL from "../../Shared/baseURL";
import { toast } from "react-toastify";

export default function TopBar() {
  function logout() {
    toast.info("Çıkış yapılıyor...", {
      toastId: "logout-toas-info",
    });
    axios
      .delete(`${baseURL}/logout`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Çıkış yapıldı!", {
          toastId: "logout-toas-success",
        });
        console.log(res);
      })
      .catch((err) => {
        toast.error("Çıkış yapılırken bir hata oluştu!", {
          toastId: "logout-toas-error",
        });
        console.log(err);
      })
      .finally(() => {
        window.location.href = "/";
      });
  }

  return (
    <div className="top-bar">
      <div id="top-bar-img-div">
        <img
          onClick={() => {
            window.location.href = "/";
          }}
          alt="Yerson Logo"
          src={CONSTANTS.YERSON_LOGO_SOURCE}
        />
      </div>
      <div id="top-bar-nav-items">
        <a href="/yenirapor">Yeni Rapor</a>
        <a href="/raporlar">Raporlar</a>
        <button onClick={logout}>Çıkış Yap</button>
      </div>
    </div>
  );
}
