import React, { useState } from "react";
import { toast } from "react-toastify";
import "./GetFileStyle.css";
export default function GetFile(props) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    "application/vnd.ms-excel.addin.macroEnabled.12",
    "application/vnd.ms-excel.template.macroEnabled.12",
  ];
  const changeHandler = (e) => {
    let selected = e.target.files[0];
    if (
      selected &&
      types.some((type) => type.toLowerCase() === selected.type.toLowerCase())
    ) {
      setFile(selected);
      setError("");
      props.validator(true);
      props.setFile(selected);
    } else {
      setFile(null);
      toast.error(
        "Hatalı dosya turu sadece pdf, word ve excel dosyaları kabul edilir",
        {
          toastId: "fileTypeError",
        }
      );
      setError("Hatali dosya tipi");
      props.validator(false);
    }
  };

  const removeSelection = () => {
    setFile(null);
    setError("");
    props.validator(false);
    props.setFile(null);
  };

  return (
    <form className="form" id="get-file-form">
      <h3>{props.title}</h3>
      <p>
        Lütfen raporunuzu yükleyiniz. Sadece pdf, word ve excel dosyaları kabul
        edilir.
      </p>
      <input name="file" type="file" onChange={changeHandler} accept={types} />
      <div className="output">
        {error && <div className="error">{error}</div>}
        {file && <div>{file.name}</div>}
        {
          // show the first page of the pdf
          file && file.type === "application/pdf" && (
            <embed src={URL.createObjectURL(file)} width="500" height="375" />
          )
        }
      </div>
      <div>
        <button onClick={removeSelection}> Raporu Sil</button>
      </div>
    </form>
  );
}
