import React, { useState, useRef } from "react";
import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import "./StepProgressStyle.css";
import GetFile from "../GetFile/GetFile";
import QrCode from "../QrCode/QrCode";
import { toast } from "react-toastify";
import SubmitReport from "../SubmitReport/SubmitReport";
import { useNavigate } from "react-router-dom";

export default function StepProgress() {
  const [fileValidator, setFileValidator] = useState(false);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const navigate = useNavigate();

  const [fileValidator2, setFileValidator2] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [submitValidator, setSubmitValidator] = useState(false);

  const stateRef = useRef();
  const stateRef2 = useRef();
  const qrStateRef = useRef();
  const file1StateRef = useRef();
  const file2StateRef = useRef();
  const submitStateRef = useRef();

  file1StateRef.current = file1;
  file2StateRef.current = file2;
  qrStateRef.current = qrCode;
  stateRef.current = fileValidator;
  stateRef2.current = fileValidator2;
  submitStateRef.current = submitValidator;
  // setup the step content
  const step1Content = (
    <GetFile
      validator={setFileValidator}
      title="QR Kod oluşturmak için rapor seçiniz"
      setFile={(file) => {
        setFile1(file);
      }}
    />
  );
  const step2Content = <QrCode file={file1StateRef} setQrCode={setQrCode} />;
  const step3Content = <h1>QR Kodu Rapora Eklediğinizden Emin Olunuz.</h1>;
  const step4Content = (
    <GetFile
      validator={setFileValidator2}
      title="QR Kodu eklediğiniz raporu seçiniz"
      setFile={setFile2}
    />
  );
  const step5Content = (
    <SubmitReport
      file={file2StateRef}
      qrCode={qrStateRef}
      validator={setSubmitValidator}
    />
  );

  function getFileValidator() {
    if (stateRef.current === false) {
      toast.error("Lütfen bir rapor seçiniz.", {
        toastId: "getFileValidatorError",
      });
    }
    return stateRef.current;
  }

  function getFileValidator2() {
    if (stateRef2.current === false) {
      toast.error("Lütfen bir rapor seçiniz.", {
        toastId: "getFileValidatorError",
      });
    }
    return stateRef2.current;
  }

  function getQRValidator() {
    // return a boolean
    if (qrStateRef.current === false) {
      toast.error("QR Kod oluşturulmalıdır.", {
        toastId: "qrValidatorError",
      });
    }
    return qrStateRef.current;
  }

  function getSubmitValidator() {
    return submitStateRef.current;
  }

  // ask user for confirmation
  function onFormSubmit() {
    // ask user for confirmation
    if (submitStateRef.current === true) {
      toast.success("Rapor başarıyla sisteme yüklendi.", {
        toastId: "submitSuccess",
      });
      window.location.href = "/";
    } else {
      if (
        window.confirm(
          "Rapor sisteme yüklenemedi. Ana Sayfaya dönmek ister misiniz?"
        )
      ) {
        window.location.href = "/";
      }
    }
  }

  const steps = [
    {
      label: "Rapor Seç",
      subtitle: "QR Kod oluşturmak için rapor seçiniz",
      name: "step 1",
      content: step1Content,
      validator: getFileValidator,
    },
    {
      label: "QR Kod Oluştur",
      name: "step 2",
      content: step2Content,
      validator: getQRValidator,
    },
    {
      label: "QR Kodu Rapora Ekle",
      name: "step 3",
      content: step3Content,
      validator: getQRValidator,
    },
    {
      label: "Rapor Seç",
      subtitle: "QR Kod eklenmiş raporu seçiniz",
      name: "step 4",
      content: step4Content,
      validator: getFileValidator2,
    },
    {
      label: "Sisteme Yükle",
      name: "step 5",
      content: step5Content,
      validator: getSubmitValidator,
    },
  ];
  return (
    // render the progress bar
    <StepProgressBar
      startingStep={0}
      onSubmit={onFormSubmit}
      steps={steps}
      nextBtnName="İleri"
      previousBtnName="Geri"
      submitBtnName="Tamamla"
      contentClass="step-progress-content"
      labelClass="step-progress-label"
    />
  );
}
