import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import QRCode from "qrcode";
import { sha256 } from "js-sha256";
import "./QrCodeStyle.css";

export default function QrCode(props) {
  const [qrCode, setQrCode] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.file.current === null) {
      toast.error("Lütfen bir rapor seçiniz.", {
        toastId: "getFileValidatorError",
      });
      setError("Lütfen bir rapor seçiniz.");
      setIsLoading(false);
      return;
    }
    //alert("qr " + props.file.current.name);
    generateQR(props.file.current.name);
  }, [props.file]);

  const generateQR = async (fileName) => {
    try {
      const { qrDataURL, fileID } = createQRUrl(fileName);
      const createdQR = await QRCode.toDataURL(qrDataURL);
      setQrCode(createdQR);
      const qrCodeData = {
        qrCode: createdQR,
        qrDataURL: qrDataURL,
        fileID: fileID,
      };
      props.setQrCode(qrCodeData);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      toast.error("QR Kod Oluşturulamadı", {
        toastId: "qrCodeError",
      });
      setError(err);
      setIsLoading(false);
    }
  };

  function createQRUrl(fileName) {
    const id = fileName + new Date().getTime();
    const hashedId = sha256(id);
    const URL = getURL(hashedId);
    return { qrDataURL: URL, fileID: hashedId };
  }

  function getURL(id) {
    const baseURL = "https://yerson.s3.eu-central-1.amazonaws.com/reports/";
    return baseURL + id;
  }

  function downloadQRCode() {
    let image = document.getElementById("qrCodeImg").src;
    let link = document.createElement("a");
    link.href = image;
    const fileName = props.file.current.name.split(".")[0];
    link.download = fileName + "_QRCode.png";
    link.click();

    toast.success("QR Kod başarıyla indirildi.", {
      toastId: "qrCodeDownloadSuccess",
    });
  }

  function copyQRCodeClipboard() {
    try {
      const base64Data = qrCode.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type as needed
      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard
        .write([item])
        .then(() => {
          toast.success(
            "QR Kod başarıyla kopyalandı. CTRL + V ile yapıştırabilirsiniz.",
            {
              toastId: "qrCodeCopySuccess",
            }
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error("QR Kod kopyalanırken hata oluştu. " + error, {
            toastId: "qrCodeCopyError",
          });
        });
    } catch (err) {
      console.error(err);
      toast.error("QR Kod kopyalanırken hata oluştu.", {
        toastId: "qrCodeCopyError",
      });
    }
  }

  const content = qrCode ? (
    <div>
      <div id="qr-code-img-div">
        <img id="qrCodeImg" src={qrCode} alt="qrCode" />
      </div>
      <button onClick={copyQRCodeClipboard}>QR Kodu Kopyala</button>
      <button onClick={downloadQRCode}>QR Kodu İndir</button>
    </div>
  ) : (
    <div>
      <h1>QR Kod Oluşturulamadı</h1>
    </div>
  );

  const loading = <h1>QR Kod Oluşturuluyor...</h1>;

  return <div id="qr-code-div">{isLoading ? loading : content}</div>;
}
