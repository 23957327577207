import React from "react";
import "./ReportItemStyle.css";
import { toast } from "react-toastify";
import axios from "axios";
import baseURL from "../../Shared/baseURL";
import { Buffer } from "buffer";

export default function ReportItem(props) {
  function copyURL() {
    const url = props.report.s3_report_url;
    navigator.clipboard.writeText(url);
    toast.info(
      "Rapor Linki Panoya Kopyalandı. Dilerseniz yapıştırabilirsiniz.",
      {
        toastId: "copyURL",
      }
    );
  }

  function sendReport() {
    const fileName = props.report.file_name.split(".")[0];
    const emailSubject = encodeURIComponent(fileName + " Raporu Hakkında");
    const fileUrl = props.report.s3_report_url;
    const emailBody = encodeURIComponent(
      `Sayın    ,\n\n${fileName} isimli raporunuza şu adresten ulaşabilirsiniz:\n\n${fileUrl}\n\nSaygılarımızla,\n`
    );
    const url = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${emailSubject}&body=${emailBody}`;
    window.open(url, "_blank");
  }

  async function deleteReport() {
    try {
      toast.loading("Rapor Siliniyor...", {
        toastId: "deleteReportLoading",
      });
      const response = await axios.delete(
        `${baseURL}/reports/` + props.report.id,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Rapor Başarıyla Silindi.", {
          toastId: "deleteReportSuccess",
        });
        window.location.reload();
      } else {
        throw new Error("Rapor Silinemedi.");
      }
    } catch (err) {
      toast.error("Rapor Silinemedi.", {
        toastId: "deleteReportError",
      });
    }
  }

  function downloadQRCode() {
    const image = document.getElementById("report-item-qrCodeImg").src;
    let link = document.createElement("a");
    link.href = image;
    link.target = "_blank";
    link.download = props.report.file_name + "QRCode" + ".png";
    link.click();
  }

  return (
    <tr id="report-item-tr">
      <td>
        <img
          id="report-item-qrCodeImg"
          src={props.report.s3_qr_code_url}
          alt="qrCode"
          onClick={downloadQRCode}
        />
      </td>
      <td>{props.report.file_name}</td>
      <td>
        <button className="report-item-button">
          <a href={props.report.s3_report_url} target="_blank" rel="noreferrer">
            Raporu Görüntüle
          </a>
        </button>
      </td>
      <td>
        <button className="report-item-button" onClick={copyURL}>
          Raporun Linkini Kopyala
        </button>
      </td>
      <td>
        <button className="report-item-button" onClick={sendReport}>
          Raporu Maille Gönder
        </button>
      </td>
      <td>
        <button
          className="report-item-button"
          id="delete-button"
          onClick={deleteReport}
        >
          Raporu Sil
        </button>
      </td>
    </tr>
  );
}
